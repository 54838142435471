<template>
  <div class="container">
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} orders</span>
      <span v-else class="text-truncate">Found {{meta.total}} orders that match '{{query}}'</span>
      <div class="d-flex ml-auto">
        <select
          v-model="filterStatus"
          class="form-control custom-select w-auto"
          @change="refresh">
          <option value="">All statuses</option>
          <option
            v-for="(value, key) in OrderStatuses"
            :key="value"
            :value="value">
            {{key}}
          </option>
        </select>
        <router-link
          v-if="can(uiPermissions.ORDERS_CREATE)"
          to="/orders/new"
          class="btn btn-primary ml-2">
          New order
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <dimmer :active="listLoading">
              <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                <thead>
                  <tr>
                    <th class="w-1"></th>
                    <th>Order</th>
                    <th>Created at</th>
                    <th>Delivery</th>
                    <th>Transporter</th>
                    <th class="text-center">Total</th>
                    <th>Status</th>
                    <th class="w-1">Last updated</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.id"
                    :class="{'outline outline-danger': item.complaints}">
                    <td class="text-center">
                      <span class="avatar" :style="`background-image: url(${identicon(item.id)});`"></span>
                    </td>
                    <td>
                      <div>
                        <router-link v-if="can(uiPermissions.ORDERS_VIEW)" :to="`/orders/${item.id}`">
                          #{{item.id}}
                        </router-link>
                        <span v-else>
                          #{{item.id}}
                        </span>
                        for
                        <router-link v-if="can(uiPermissions.CUSTOMERS_VIEW)" :to="`/users/${item.item.customer.id}`">
                          {{truncate(displayName(item.item.customer.user))}}
                        </router-link>
                        <span v-else>
                          {{truncate(displayName(item.item.customer.user))}}
                        </span>
                      </div>
                      <div class="small text-muted">
                        {{truncate(item.item.customer.user.email)}}
                      </div>
                    </td>
                    <td>
                      {{moment.utc(item.createdAt).local().format('D MMM YYYY')}}
                    </td>
                    <td>
                      {{moment(item.deliveryDate).format('dddd, D MMM YYYY')}}
                    </td>
                    <td>{{item.transporter || 'N/A'}}</td>
                    <td class="text-center">AED {{numeral(item.receipt.grandTotal).format('0,0[.]00')}}</td>
                    <td>{{status(item)}}</td>
                    <td>
                      {{moment.utc(item.updatedAt).local().fromNow()}}
                    </td>
                    <td class="text-center">
                      <div v-if="can([uiPermissions.ORDERS_VIEW])" class="item-action dropdown">
                        <a
                          tabindex="0"
                          data-toggle="dropdown"
                          class="icon"><i class="fe fe-more-vertical"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <router-link
                            v-if="can(uiPermissions.ORDERS_VIEW)"
                            :to="`/orders/${item.id}`"
                            class="dropdown-item">
                            <i class="dropdown-icon fe fe-edit"></i> Edit
                          </router-link>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="items.length === 0">
                    <td colspan="9">There are no results matching your criteria.</td>
                  </tr>
                </tbody>
              </table>
            </dimmer>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {ordersNew} from '@/services';
import OrderStatuses from '@hellochef/shared-js/enums/OrderStatuses';

export default {
  components: {
    Pagination,
  },
  mixins: [
    list,
  ],
  data() {
    return {
      OrderStatuses,
      filterStatus: '',
    };
  },
  methods: {
    fetchData(page) {
      return ordersNew.getByParameters({
        limit: this.limit,
        page,
        query: this.query,
        status: this.filterStatus,
      });
    },
    status(item) {
      return Object.keys(OrderStatuses).find(key => OrderStatuses[key] === item.status);
    },
  },
};

</script>
